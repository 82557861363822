import React from "react";
import gsap from "gsap";
import { TweenMax, TimelineLite, TweenLite, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Scrollbar, { ScrollbarPlugin } from "smooth-scrollbar";
import lottie from "lottie-web";
import { Preloader, Placeholder } from "react-preloading-screen";

import { useLocation, Link } from "react-router-dom";

import SimpleSlider from "./components/SimpleSlider";
import SvCard from "./components/SvCard";

import Logo from "./assets/svg/Logo";
import LogoName from "./assets/svg/LogoName";
// import Play from "./assets/svg/Play";
import animBusiness from "./assets/data/business.json";
import animTech from "./assets/data/tech.json";
import animData from "./assets/data/data.json";
import animProcess from "./assets/data/process.json";
import animLogo from "./assets/data/pbsgroup.json";

import PbsTeam from "../media/pbs-team.ebb30e3f.jpg";
import PbsTech from "../media/pbs-tech@2x.705fd9ed.jpg";
import importImg from "../media/pbs-import.c7131f1a.webp";
import exportImg from "../media/pbs-export.c278f1ce.webp";
import agroImg from "../media/pbs-agro.71e0b3f7.webp";
import logisticImg from "../media/pbs-logistic.db477391.webp";
import declarantImg from "../media/pbs-declarant.d515eca8.webp";
import buildImg from "../media/pbs-build.bff2af89.webp";
import Icon from "./assets/svg/Icon";

import logoPre from "./assets/img/logo.svg";

// import teamImg from "./assets/img/pbs-team.jpg";
// import techImg from "./assets/img/pbs-tech@2x.jpg";
// import exportImg from "./assets/img/pbs-export.webp";
// import agroImg from "./assets/img/pbs-agro.webp";
// import logisticImg from "./assets/img/pbs-logistic.webp";
// import declarantImg from "./assets/img/pbs-declarant.webp";
// import buildImg from "./assets/img/pbs-build.webp";

// import animBusiness from "./assets/svg/business.json";
// import animTech from "./assets/svg/tech.json";
// import animData from "./assets/svg/data.json";
// import animProcess from "./assets/svg/process.json";
// import animLogo from "./assets/svg/pbsgroup.json";

gsap.registerPlugin(ScrollTrigger);

class DisableScrollPlugin extends ScrollbarPlugin {
  static pluginName = "disableScroll";

  static defaultOptions = {
    direction: null,
  };

  transformDelta(delta) {
    if (this.options.direction) {
      delta[this.options.direction] = 0;
    }

    return { ...delta };
  }
}

Scrollbar.use(DisableScrollPlugin);

const useWindowSize = () => {
  const [size, setSize] = React.useState([
    window.innerHeight,
    window.innerWidth,
  ]);

  React.useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return size;
};

const useHover = () => {
  const pBtnRef = React.useRef(null);
  const [hovered, setHovered] = React.useState(false);

  const enter = () => setHovered(true);
  const leave = () => setHovered(false);

  React.useEffect(() => {
    const pbtn = pBtnRef.current;
    pbtn.addEventListener("mouseenter", enter);
    pbtn.addEventListener("mouseleave", leave);

    return () => {
      pbtn.removeEventListener("mouseenter", enter);
      pbtn.removeEventListener("mouseleave", leave);
    };
  }, [pBtnRef]);

  return [pBtnRef, hovered];
};

function App() {
  const [height, width] = useWindowSize();
  const [pBtnRef, hovered] = useHover();

  const aboutSubtitle = React.useRef(null);
  const aboutTitle = React.useRef(null);
  const infoSubtitle = React.useRef(null);
  const infoTitle = React.useRef(null);
  const importSubtitle = React.useRef(null);
  const importTitle = React.useRef(null);
  const exportSubtitle = React.useRef(null);
  const exportTitle = React.useRef(null);
  const agroTitle = React.useRef(null);
  const agroSubtitle = React.useRef(null);
  const logisticTitle = React.useRef(null);
  const logisticSubtitle = React.useRef(null);
  const declarantTitle = React.useRef(null);
  const declarantSubtitle = React.useRef(null);
  const buildTitle = React.useRef(null);
  const buildSubtitle = React.useRef(null);
  const business = React.useRef(null);
  const tech = React.useRef(null);
  const data = React.useRef(null);
  const process = React.useRef(null);
  const svCard = React.useRef(null);
  const appRef = React.useRef(null);
  const bgFirstImg = React.useRef(null);
  const bgSecondImg = React.useRef(null);
  const logoRef = React.useRef(null);
  const logoNameRef = React.useRef(null);
  const listRef = React.useRef(null);
  const langsRef = React.useRef(null);
  const contentRef = React.useRef(null);
  const playBtnRef = React.useRef(null);
  const readMoreRef = React.useRef(null);
  const navRef = React.useRef(null);
  const storkeLogo = React.useRef(null);
  const globeRef = React.useRef(null);
  const footerLofoRef = React.useRef(null);
  const traitsRef = React.useRef(null);
  const percentRef = React.useRef(null);
  let location = useLocation();

  React.useEffect(() => {
    // *** Refactoring refs *** //
    const headerSubtitle = contentRef.current.children[0].children;
    const headerTitleFirst =
      contentRef.current.children[1].children[0].children[0];
    const headerTitleSecond =
      contentRef.current.children[1].children[0].children[1];
    const readLabel = readMoreRef.current.children[0].children;
    const readLine = readMoreRef.current.children[1];
    // *** Init Scrollbar *** //
    let ScrollbarOptions = {
      renderByPixels: true,
      plugins: {
        disableScroll: { direction: "x" },
      },
    };
    let bodyScrollBar = Scrollbar.init(appRef.current, ScrollbarOptions);
    console.log(readLabel);
    bodyScrollBar.track.xAxis.element.remove();

    ScrollTrigger.scrollerProxy("body", {
      scrollTop(value) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value;
        }
        return bodyScrollBar.scrollTop;
      },
    });

    // update ScrollTrigger when scrollbar updates
    bodyScrollBar.addListener(ScrollTrigger.update);
    // *** Init timeLine *** //
    let tl = new TimelineLite();
    tl.delay(4);
    // *** Init animations header *** //
    TweenMax.to(appRef.current, 0, { css: { visibility: "visible" } });
    tl.from(
      bgFirstImg.current,
      2,
      { scale: 1.4, ease: Power3.easeOut },
      "Start"
    )
      .from(bgSecondImg.current, 2.4, { scale: 1.4, ease: Power3.easeOut }, 0.3)
      .from(logoRef.current, 1.2, { transform: "translateX(60px)" }, 0.3)
      .from(
        logoNameRef.current,
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        0.8
      )
      .from(
        listRef.current.children[0],
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        1
      )
      .from(
        listRef.current.children[1],
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        1.2
      )
      .from(
        listRef.current.children[2],
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        1.4
      )
      .from(
        listRef.current.children[3],
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        1.6
      )
      .from(
        listRef.current.children[4],
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        1.8
      )
      .from(
        listRef.current.children[5],
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        2
      )
      .from(
        listRef.current.children[6],
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        2.2
      )
      .from(
        listRef.current.children[7],
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        2.4
      )
      .from(
        langsRef.current,
        1.4,
        { transform: "translateY(-20px)", opacity: 0, ease: Power3.easeOut },
        2.6
      );

    tl.staggerFrom(
      headerSubtitle,
      1,
      {
        y: 32,
        ease: Power3.easeOut,
        delay: 1,
      },
      0.15,
      "Start"
    );
    tl.staggerFrom(
      [headerTitleFirst.children, headerTitleSecond.children],
      1,
      {
        y: 61,
        ease: Power3.easeOut,
        delay: 1.5,
      },
      0.15,
      "Start"
    )
      .from(
        playBtnRef.current,
        1.6,
        {
          opacity: 0,
          transform: "scale(.6)",
          ease: Power3.easeOut,
        },
        1.7
      )
      .from(readLabel, 1, { y: 20, ease: Power3.easeOut, delay: 1 }, 1.8)
      .from(
        readLine.children,
        1,
        { y: 32, ease: Power3.easeOut, delay: 1 },
        1.9
      );
    // *** Init animations section *** //
    const anim = lottie.loadAnimation({
      container: business.current,
      animationData: animBusiness,
      renderer: false,
      animType: "svg",
      loop: false,
      autoplay: false,
    });
    const anim2 = lottie.loadAnimation({
      container: tech.current,
      animationData: animTech,
      renderer: false,
      animType: "svg",
      loop: false,
      autoplay: false,
    });
    const anim3 = lottie.loadAnimation({
      container: data.current,
      animationData: animData,
      renderer: false,
      animType: "svg",
      loop: false,
      autoplay: false,
    });
    const anim4 = lottie.loadAnimation({
      container: process.current,
      animationData: animProcess,
      renderer: false,
      animType: "svg",
      loop: false,
      autoplay: false,
    });
    const anim5 = lottie.loadAnimation({
      container: storkeLogo.current,
      animationData: animLogo,
      renderer: false,
      animType: "svg",
      loop: false,
      autoplay: false,
    });

    const refs = [
      aboutSubtitle,
      aboutTitle,
      infoSubtitle,
      infoTitle,
      svCard,
      importSubtitle,
      importTitle,
      exportSubtitle,
      exportTitle,
      agroSubtitle,
      agroTitle,
      logisticSubtitle,
      logisticTitle,
      declarantSubtitle,
      declarantTitle,
      buildSubtitle,
      buildTitle,
    ];

    gsap.to(business.current, {
      scrollTrigger: {
        trigger: business.current,
        start: "top bottom",
        scrub: 1,
        onEnter: () => anim.goToAndPlay(1, true),
        onEnterBack: () => anim.goToAndPlay(1, true),
      },
      x: -600,
      ease: "none",
      duration: 0.5,
    });
    gsap.to(tech.current, {
      scrollTrigger: {
        trigger: tech.current,
        start: "top bottom",
        scrub: 1,
        onEnter: () => anim2.goToAndPlay(1, true),
        onEnterBack: () => anim2.goToAndPlay(1, true),
      },
      x: 600,
      ease: "none",
      duration: 0.5,
    });
    gsap.to(data.current, {
      scrollTrigger: {
        trigger: data.current,
        start: "top bottom",
        scrub: 1,
        onEnter: () => anim3.goToAndPlay(1, true),
        onEnterBack: () => anim3.goToAndPlay(1, true),
      },
      x: -600,
      ease: "none",
      duration: 0.5,
    });
    gsap.to(process.current, {
      scrollTrigger: {
        trigger: process.current,
        start: "top bottom",
        scrub: 1,
        onEnter: () => anim4.goToAndPlay(1, true),
        onEnterBack: () => anim4.goToAndPlay(1, true),
      },
      x: 600,
      ease: "none",
      duration: 0.5,
    });
    gsap.to(storkeLogo.current, {
      scrollTrigger: {
        trigger: storkeLogo.current,
        start: "top bottom",
        onEnter: () => anim5.goToAndPlay(1, true),
      },
      y: 35,
      alpha: 1,
      ease: "ease",
      duration: 0.2,
    });
    gsap.to(globeRef.current, {
      scrollTrigger: {
        trigger: globeRef.current,
        start: "top bottom",
        toggleActions: "restart reverse reverse reverse",
      },
      y: -300,
      ease: "ease",
      duration: 2.2,
    });
    gsap.to(footerLofoRef.current, {
      scrollTrigger: {
        trigger: footerLofoRef.current,
        start: "top bottom",
        toggleActions: "restart reverse reverse reverse",
      },
      y: -250,
      ease: "ease",
      duration: 1.4,
    });
    gsap.to(traitsRef.current, {
      scrollTrigger: {
        trigger: traitsRef.current,
        start: "top bottom",
        toggleActions: "restart reverse reverse reverse",
      },
      y: -250,
      ease: "ease",
      duration: 1,
    });

    refs.forEach((ref, index) => {
      return gsap.to(ref.current, {
        scrollTrigger: {
          trigger: ref.current,
          start: "-225px center",
          scrub: 1,
          id: index,
        },
        y: ref === svCard ? -300 : -150,
        ease: "none",
        duration: 20,
      });
    });
  }, []);

  React.useEffect(() => {
    let ScrollbarOptions = {
      renderByPixels: true,
      plugins: {
        disableScroll: { direction: "x" },
      },
    };
    let bodyScrollBar = Scrollbar.init(appRef.current, ScrollbarOptions);

    bodyScrollBar.track.xAxis.element.remove();

    ScrollTrigger.scrollerProxy("body", {
      scrollTop(value) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value;
        }
        return bodyScrollBar.scrollTop;
      },
    });

    // update ScrollTrigger when scrollbar updates
    bodyScrollBar.addListener(ScrollTrigger.update);

    const hash = location.hash;

    if (hash) {
      let toSection;
      switch (hash) {
        case "#import":
          toSection = appRef.current.children[0].children[4];
          break;
        case "#export":
          toSection = appRef.current.children[0].children[5];
          break;
        case "#agro":
          toSection = appRef.current.children[0].children[6];
          break;
        case "#logistic":
          toSection = appRef.current.children[0].children[7];
          break;
        case "#declarant":
          toSection = appRef.current.children[0].children[8];
          break;
        case "#build":
          toSection = appRef.current.children[0].children[9];
          break;
        case "#more":
          toSection = appRef.current.children[0].children[1];
          break;
        default:
          toSection = appRef.current.children[0].children[1];
      }
      requestAnimationFrame(function () {
        bodyScrollBar.scrollIntoView(toSection, {
          offsetTop: -bodyScrollBar.containerEl.scrollTop,
        });
      });
    }
  }, [location]);

  React.useEffect(() => {
    let counter = 2;
    const frame = () => {
      if (counter === 100) {
        clearInterval(id);
        setTimeout(() => {
          percentRef.current.innerHTML = `{ Загрузка... }`;
        }, 600);
      } else if (percentRef.current && counter <= 100) {
        counter += 2;
        percentRef.current.innerHTML = `{ ${counter} % }`;
      }
    };
    const id = setInterval(frame, 50);
  }, []);

  React.useEffect(() => {
    let ScrollbarOptions = {
      renderByPixels: true,
      plugins: {
        disableScroll: { direction: "x" },
      },
    };
    let bodyScrollBar = Scrollbar.init(appRef.current, ScrollbarOptions);

    bodyScrollBar.track.xAxis.element.remove();

    ScrollTrigger.scrollerProxy("body", {
      scrollTop(value) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value;
        }
        return bodyScrollBar.scrollTop;
      },
    });

    // update ScrollTrigger when scrollbar updates
    bodyScrollBar.addListener(ScrollTrigger.update);

    var currentScrollTop = document
        .querySelector(".scroll-content")
        .getBoundingClientRect().top,
      isVisible = true;

    function show() {
      if (!isVisible) {
        TweenLite.to(navRef.current, 0.6, { y: "0%", opacity: 1 }, 0);
        isVisible = true;
      }
    }

    function hide() {
      if (isVisible) {
        TweenLite.to(navRef.current, 0.6, { y: "-100%", opacity: 0 }, 0);
        isVisible = false;
      }
    }

    function refresh() {
      var newScrollTop = document
        .querySelector(".scroll-content")
        .getBoundingClientRect().top;
      if (newScrollTop < currentScrollTop) {
        hide();
      } else if (newScrollTop > currentScrollTop) {
        show();
      }
      currentScrollTop = newScrollTop;
    }

    bodyScrollBar.addListener(refresh);

    refresh();
  }, []);

  return (
    <Preloader fadeDuration="4000">
      <Placeholder>
        <div className="loader">
          <div className="loader__logo">
            <img className="loader__logo-main" src={logoPre} alt="Logo" />
          </div>
          <div className="loader__title"></div>
          <div className="loader__subtitle">ДОБРО ПОЖАЛОВАТЬ В PBS GROUP</div>
          <div className="loader__counter">
            <div className="loader__counter-inner">
              <span ref={percentRef}>0%</span>
            </div>
          </div>
        </div>
      </Placeholder>
      <>
        <nav ref={navRef}>
          <div className="container">
            <div className="nav-inner">
              <div className="nav-inner__logo">
                <Link to="/" className="nav-inner__logo-inner">
                  <Icon />
                  {/* <Logo forwardRef={logoRef} />
                  <span></span>
                  <LogoName forwardRef={logoNameRef} /> */}
                </Link>
              </div>
              <div className="nav-inner__list">
                <div className="nav-inner__list-inner">
                  <ul ref={listRef} className="nav__list">
                    <li className="nav__list-item">
                      <a href="#import" className="nav__list-link">
                        <span className="nav__list-item-label">Импорт</span>
                      </a>
                    </li>
                    <li className="nav__list-item">
                      <a href="#export" className="nav__list-link">
                        <span className="nav__list-item-label">Экспорт</span>
                      </a>
                    </li>
                    <li className="nav__list-item">
                      <a href="#agro" className="nav__list-link">
                        <span className="nav__list-item-label">
                          Агрокультура
                        </span>
                      </a>
                    </li>
                    <li className="nav__list-item">
                      <a href="#logistic" className="nav__list-link">
                        <span className="nav__list-item-label">
                          Грузоперевозки
                        </span>
                      </a>
                    </li>
                    <li className="nav__list-item">
                      <a href="#declarant" className="nav__list-link">
                        <span className="nav__list-item-label">
                          Декларантские услуги
                        </span>
                      </a>
                    </li>
                    <li className="nav__list-item">
                      <a href="#build" className="nav__list-link">
                        <span className="nav__list-item-label">
                          Строительство
                        </span>
                      </a>
                    </li>
                    <li className="nav__list-item">
                      <a href="https://it-pbs.uz" className="nav__list-link">
                        <span className="nav__list-item-label">ИТ</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="nav-inner__lang">
                <div className="nav-inner__lang-inner">
                  <ul ref={langsRef} className="nav__lang-list">
                    <li className="nav__lang-list-item">
                      <a href="/" className="nav__lang-link">
                        <span className="nav__list-item-label">RU</span>
                      </a>
                    </li>
                    <li className="nav__lang-list-item">
                      <a href="/" className="nav__lang-link">
                        <span className="nav__list-item-label">EN</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div ref={appRef} className="App">
          <header>
            <div className="header-bg">
              <div
                className={`header-bg__buildings ${
                  hovered ? "animateBg" : "outBg"
                }`}
                ref={bgFirstImg}
              ></div>
              <div
                className={`header-bg__clouds ${
                  hovered ? "animateBg" : "outBg"
                }`}
                ref={bgSecondImg}
              ></div>
            </div>
            <div className="container">
              <div className="header-inner">
                <div className="header-inner__content" ref={contentRef}>
                  <div className="header-inner__content-subtitle">
                    <h2 className="header-inner__content-subtitle__inner">
                      Ваш успех -<span> наше единственное дело</span>
                    </h2>
                  </div>
                  <div className="header-inner__content-title">
                    <h1 className="header-inner__content-title__inner">
                      <div className="content-title__line">
                        <div className="content-title__line-inner">
                          Опыт, которому можно доверять и положиться!
                        </div>
                      </div>

                      <div className="content-title__line">
                        <div className="content-title__line-inner">
                          {/* Агрокультурага ссылка!<span> AgriOne</span> */}
                        </div>
                      </div>
                    </h1>
                  </div>
                  <div className="header-inner__content-play" ref={pBtnRef}>
                    <div
                      className="header-inner__content-play__inner"
                      ref={playBtnRef}
                    >
                      ㅤ ㅤ
                    </div>
                  </div>
                </div>
                <div className="header-inner__action">
                  <div
                    ref={readMoreRef}
                    className="header-inner__action-discover"
                  >
                    <div className="label">
                      <div className="header-inner__action-discover__inner">
                        <a href="#more" className="label noselect">
                          Подробнее
                        </a>
                      </div>
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <div className="header-inner__action-discover__line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section id="about">
            <div className="container">
              <div className="section-inner">
                <div className="section-inner__content">
                  <div className="content-inner">
                    <div
                      ref={aboutSubtitle}
                      className="content-inner__subtitle"
                    >
                      <h4>
                        ПО ЭТОМУ ПРИЧИНУ <span>PBS GROUP</span> СОСТАВЛЯЕТСЯ ИЗ
                      </h4>
                    </div>
                    <div ref={aboutTitle} className="content-inner__title">
                      <h3>
                        Бренды, специализирующиеся на каждом из элементов,
                        составляющих{" "}
                        <span>новую цепочку создания стоимости:</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="section-inner__media">
                  <div className="media-inner">
                    <img src={PbsTeam} alt="" />
                  </div>
                </div>
              </div>
              <div>
                <div ref={business} className="section-business" />
                <div ref={tech} className="section-tech" />
                <div ref={data} className="section-data" />
                <div ref={process} className="section-process" />
              </div>
            </div>
          </section>

          <section id="info">
            <div className="container">
              <div className="section-inner">
                <div className="section-inner__content">
                  <div className="content-inner">
                    <div ref={infoSubtitle} className="content-inner__subtitle">
                      <h4>Группа компании</h4>
                    </div>
                    <div ref={infoTitle} className="content-inner__title">
                      <h3>
                        Способность предоставлять более{" "}
                        <span>эффективные и инновационные решения</span> во всех
                        аспектах компании или проекта благодаря специализации
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="section-inner__media">
                  <div className="media-inner">
                    <img src={PbsTech} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {width <= 600 ? (
            <section id="services-slider">
              <div className="container">
                <div className="section-inner">
                  <div className="section-slider__slider">
                    <SimpleSlider />
                  </div>
                  <div></div>
                </div>
              </div>
            </section>
          ) : (
            <section id="services">
              <div className="container">
                <div className="section-inner">
                  <div className="section-inner__services">
                    <div ref={svCard} className="services-left">
                      <div className="services-left__inner">
                        <SvCard
                          hasBorder
                          label="Импорт"
                          info="Комплексные поставки любых товаров из любой точки мира, сырьё, запасные
        части, оборудования, товаров для строительства и промышленного
        использования, линий производства."
                        ></SvCard>
                        <SvCard
                          check={true}
                          hasBorder
                          label="Агрокультура"
                          info="Группа компаний “PBS Group” занимается сельскохозяйственным производством в разных регионах Республики Узбекистан"
                          button="•••"
                          href="https://agrione.uz/"
                        ></SvCard>
                        <SvCard
                          label="Декларантские услуги"
                          info="Предлагаем услуги таможенных декларантов 24 часа в сутки 7 дней в неделю. Работаем быстро, качественно и предлагаем доступные цены на услуги."
                        ></SvCard>
                        <SvCard
                          label="ИТ услуги"
                          info="Компания ООО «PBS GROUP» является лидером в сфере IT-разработок, интеграции, 
                          автоматизации для государственных и негосударственных коммерческих предприятий 
                          не только на территории Республики Узбекистан, но и за рубежом."
                        ></SvCard>
                      </div>
                    </div>
                    <div className="services-right">
                      <div className="services-right__inner">
                        <SvCard
                          hasBorder
                          label="Экспорт"
                          info="Cвежей и переработанной плодоовощной продукции и осуществляет экспортные поставки на основе прямых экспортных контрактов с иностранными партнерами."
                        ></SvCard>
                        <SvCard
                          hasBorder
                          label="Грузоперевозки"
                          info="Грузоперевозки являются одним из важнейших и приоритетных направлений, при помощи которой возможно обеспечение динамичного роста экономик государств."
                        ></SvCard>
                        <SvCard
                          label="Строительство"
                          info="PBS Group осуществляет строительство многоэтажных жилых домов. По всем современным требованиям строительства."
                        ></SvCard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="services" id="import">
            <div className="container">
              <div className="section-inner">
                <div className="section-inner__content">
                  <div className="content-inner">
                    <div
                      ref={importSubtitle}
                      className="content-inner__subtitle"
                    >
                      <h1>Импорт</h1>
                    </div>
                    <div ref={importTitle} className="content-inner__title">
                      <h3>
                        Группа компаний “PBS Group” предлагает Вам комплексные
                        поставки любых товаров из любой точки мира, сырьё,
                        запасные части, оборудования, товаров для строительства
                        и промышленного использования, линий производства.
                        <br />
                        <br />
                        С нами выгодно работать, потому что мы предлагаем
                        продукцию от разных производителей, мы не являемся
                        заинтересованными лицами в продвижении определенного
                        бренда, мы предлагаем то, что наиболее подходит вам.
                        <br />
                        <br />
                        Мы предпочитаем работать напрямую с производителями, без
                        посредников, помимо этого, занимаемся таможенной
                        очисткой, оказываем дополнительные услуги по таможенному
                        оформлению и вывозу товара, что также делает выгодным
                        сотрудничество с нами.
                        <br />
                        <br />
                        За нашими плечами огромное, плодотворное, основанное на
                        доверие, долгое сотрудничество и опыт работы с ведущими
                        мировыми компаниями из разных стран и континентов.
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="section-inner__media">
                  <div className="media-inner">
                    <img src={importImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services" id="export">
            <div className="container">
              <div className="section-inner">
                <div className="section-inner__content">
                  <div className="content-inner">
                    <div
                      ref={exportSubtitle}
                      className="content-inner__subtitle"
                    >
                      <h1>Экспорт</h1>
                    </div>
                    <div ref={exportTitle} className="content-inner__title">
                      <h3>
                        Группа компаний “PBS Group” занимается внешней торговлей
                        по экспорту свежей и переработанной плодоовощной
                        продукции и осуществляет экспортные поставки на основе
                        прямых экспортных контрактов с иностранными партнерами,
                        а также по договорам комиссии, заключенным с агрофирмами
                        и перерабатывающими предприятиями.
                        <br />
                        <br />В структуру “PBS Group” входит
                        торгово-логистические центры, торгово-закупочные базы и
                        холодильные хранилища по заготовке, хранению, калибровке
                        и сортировке свежей плодоовощной продукции, торговые
                        дома и представительства за рубежом.
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="section-inner__media">
                  <div className="media-inner">
                    <img src={exportImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services" id="agro">
            <div className="container">
              <div className="section-inner">
                <div className="section-inner__content">
                  <div className="content-inner">
                    <div ref={agroSubtitle} className="content-inner__subtitle">
                      <h1>Агрокультура</h1>
                    </div>
                    <div ref={agroTitle} className="content-inner__title">
                      <h3>
                        Группа компаний “PBS Group” занимается
                        сельскохозяйственным производством в разных регионах
                        Республики Узбекистан. Компания предлагает к продаже
                        такую сельхоз продукцию собственного выращивания как
                        маш, салат айсберг, брокколи, свежий салат романа,
                        радича с хозяйств расположенных на западе и
                        северо-востоке Республики Узбекистан.
                        <br />
                        <br />
                        Перечисленная продукция выращена под жестким контролем
                        опытных специалистов данной сферы учитывая все основные
                        факторы для благополучного выращивания, что гарантирует
                        высокое качество.
                      </h3>
                      <a className="button outline" href="https://agrione.uz">
                        Подробнее
                      </a>
                    </div>
                  </div>
                </div>
                <div className="section-inner__media">
                  <div className="media-inner">
                    <img src={agroImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services" id="logistic">
            <div className="container">
              <div className="section-inner">
                <div className="section-inner__content">
                  <div className="content-inner" style={{ marginLeft: "auto" }}>
                    <div
                      ref={logisticSubtitle}
                      className="content-inner__subtitle"
                    >
                      <h1>Грузоперевозки</h1>
                    </div>
                    <div ref={logisticTitle} className="content-inner__title">
                      <h3>
                        Грузоперевозки являются одним из важнейших и
                        приоритетных направлений, при помощи которой возможно
                        обеспечение динамичного роста экономик государств.
                        <br />
                        <br />В последнее время большое внимание уделяется
                        развитию транспортной сети и дорожной инфраструктуры,
                        что благотворно сказывается на развитии и качестве
                        транспортных услуг. С увеличением количества
                        грузоперевозок, встает вопрос о предложении и спросе и,
                        как следствие, качестве и оперативности предлагаемых
                        услуг, для тех или иных участников рынка грузоперевозок.
                        Каждый из участников рынка проследует фактически одну и
                        ту же цель, обеспечение доставки - отправки груза в
                        установленный временной интервал и должном качестве и
                        при оптимальных финансовых затратах. <br />
                        <br />
                        Учитывая вышеуказанный факт, группа компаний “PBS Group”
                        предлагает свои услуги по грузоперевозкам. Исходя из
                        специфики вашего груза и маршрута, мы подберём наиболее
                        подходящий вариант перевозки с выгодными ценам и
                        сроками.
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="section-inner__media">
                  <div className="media-inner" style={{ marginLeft: 0 }}>
                    <img src={logisticImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services" id="declarant">
            <div className="container">
              <div className="section-inner">
                <div className="section-inner__content">
                  <div className="content-inner">
                    <div
                      ref={declarantSubtitle}
                      className="content-inner__subtitle"
                    >
                      <h1>Декларантские услуги</h1>
                    </div>
                    <div ref={declarantTitle} className="content-inner__title">
                      <h3>
                        Для вашего удобства группа компаний “PBS Group”
                        предлагаем услуги таможенных декларантов 24 часа в сутки
                        7 дней в неделю.
                        <br />
                        <br />
                        Работаем быстро, качественно и предлагаем доступные цены
                        на услуги. В спектр декларантских услуг входят:
                        Процедуры импорта, экспорта, транзита, временного ввоза
                        для переработки под таможенным контролем и документы для
                        других процедур Упрощённое оформление некоторых
                        таможенных процедур ТИР-КАРНЕТ, CMR, АТА КАРНЕТ, Т2L{" "}
                        <br />
                        <br />
                        Предварительное оформление электронной таможенной
                        декларации для таможни Республики Узбекистан.
                        Консультации по вопросам оформления таможенных процедур
                        К вашим услугам таможенные декларанты, которые работают
                        в этой области более 10 лет и вся команда консультантов.
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="section-inner__media">
                  <div className="media-inner">
                    <img src={declarantImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services" id="build">
            <div className="container">
              <div className="section-inner">
                <div className="section-inner__content">
                  <div className="content-inner" style={{ marginLeft: "auto" }}>
                    <div
                      ref={buildSubtitle}
                      className="content-inner__subtitle"
                    >
                      <h1>Строительство</h1>
                    </div>
                    <div ref={buildTitle} className="content-inner__title">
                      <h3>
                        PBS Group осуществляет строительство многоэтажных жилых
                        домов. По всем современным требованиям строительства —
                        возведение многоэтажных зданий невозможно без четкого
                        плана действий, а именно строительного проекта, который
                        разрабатывается в соответствии с нормативной
                        документацией.
                        <br />
                        <br />
                        Стоит также заметить, что проектирование многоэтажных
                        зданий представляет собой довольно длительный процесс,
                        поскольку подразумевает детальную проработку каждой
                        части архитектурного строения в отдельности.
                        <br />
                        <br />
                        Другим важным фактом является то, что проектирование
                        осуществляется в соответствии с архитектурными
                        особенностями местности, где будет располагаться
                        строительный объект. Важно осуществить грамотный
                        контроль, подготавливаемой документации, с проверкой
                        расчетов и утверждением плана застройки, в различных
                        инстанциях. Наши дома соответствуют высоким стандартам
                        качества, возведение объектов выполняется в
                        запланированные сроки при оптимальной стоимости
                        строительства.
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="section-inner__media">
                  <div className="media-inner" style={{ marginLeft: 0 }}>
                    <img src={buildImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer>
            <div className="footer">
              <div className="footer-globe" ref={globeRef}></div>
              <div className="footer-logo" ref={footerLofoRef}>
                <Logo />
                <span />
                <LogoName />
              </div>
              <div className="container">
                <div className="footer-inner">
                  <div className="footer-inner__cols">
                    <div className="footer__traits" ref={traitsRef}>
                      <div className="footer__traits-single"></div>
                      <div className="footer__traits-single"></div>
                      <div className="footer__traits-single"></div>
                    </div>
                    <div className="footer__anim">
                      <div
                        className="footer__anim-storke"
                        ref={storkeLogo}
                      ></div>
                    </div>
                    <div className="footer__copyrights">
                      <div className="footer__copyrights-item"></div>
                      <div className="footer__copyrights-item">
                        © Since 2007 PBS Group
                      </div>
                      <div className="footer__copyrights-item"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    </Preloader>
  );
}

export default App;
