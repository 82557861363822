import React from "react";

function Star() {
  return (
    <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
      <polygon points="13 6 7.71 6 11.45 2.26 10.74 1.55 7 5.29 7 0 6 0 6 5.29 2.26 1.55 1.55 2.26 5.29 6 0 6 0 7 5.29 7 1.55 10.74 2.26 11.45 6 7.71 6 13 7 13 7 7.71 10.74 11.45 11.45 10.74 7.71 7 13 7 13 6"></polygon>
    </svg>
  );
}

export default Star;
