import React from "react";

function Logo({ forwardRef }) {
  return (
    <>
      <svg
        ref={forwardRef}
        width="190"
        height="190"
        viewBox="0 0 190 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="logo"
      >
        <path
          d="M0.250779 86.4104C0.301644 87.1659 0.357491 88.0236 0.357491 88.0236C0.357491 88.0236 17.9669 92.1235 25.2446 91.793C41.5983 91.0415 57.96 89.4519 74.2001 87.3154C101.438 83.727 128.385 77.3922 156.108 78.5293C167.134 78.9818 178.16 80.4494 189.749 81.5078C189.788 75.1062 187.587 68.6967 184.976 62.4327C184.661 61.6733 182.83 61.331 181.652 61.1736C167.894 59.3362 154.265 60.6661 140.927 63.9751C118.954 69.4206 97.2168 75.8695 75.1645 80.9806C63.1663 83.7663 50.7771 85.3559 38.4756 86.2176C26.0206 87.095 13.4548 86.4104 0.250779 86.4104Z"
          fill="white"
        />
        <path
          d="M1.16423 79.4776C11.0959 79.7136 21.5742 78.4939 30.73 75.6728C56.5068 67.7287 81.9225 58.5886 107.459 49.9127C125.936 43.6409 144.688 38.6282 164.298 37.4438C166.055 37.3376 167.807 37.1763 170.205 36.9914C165.732 29.6218 159.4 24.794 153.465 19.5176C151.591 17.8533 148.204 15.8427 148.204 15.8427C148.204 15.8427 143.435 16.5942 140.758 17.4874C122.151 23.6883 105.085 32.9584 88.1829 42.6179C62.0122 57.5695 34.888 69.1177 5.01503 74.3153C3.97379 74.6178 2.9136 74.8518 1.84144 75.0157C1.58911 76.0898 1.00266 78.4742 1.16423 79.4776Z"
          fill="white"
        />
        <path
          d="M0.679693 97.109C0.51812 97.593 0.89613 98.561 0.89613 98.561C0.89613 98.561 23.5941 108.665 35.5025 109.912C56.7673 112.143 78.1776 113.237 99.4889 115.169C119.253 116.959 138.868 119.93 156.643 129.444C163.731 133.248 169.957 138.706 176.911 143.652C183.145 134.716 186.405 123.793 189.056 111.58C183.834 110.199 179.002 108.551 174.037 107.677C155.97 104.506 137.808 104.982 119.604 106.497C90.1679 108.952 60.7288 109.971 31.5251 103.998C21.354 101.917 10.7211 99.328 0.679693 97.109Z"
          fill="white"
        />
        <path
          d="M122.525 3.67285C99.6582 -2.0127 76.7793 -1.6271 54.8493 8.5912C29.4026 20.4423 12.4345 40.0446 3.3855 68.3976C11.3754 65.3365 18.3848 63.4046 24.6502 60.0916C39.4481 52.2735 53.9676 43.9596 68.4353 35.5867C86.4856 25.1245 104.38 14.3947 122.525 3.67285Z"
          fill="white"
        />
        <path
          d="M0.558041 105.6C4.63026 148.239 38.4957 183.088 81.1697 188.951C109.385 192.827 134.879 186.031 156.836 167.432C159.486 165.186 162.585 161.468 162.585 161.468C162.585 161.468 159.494 157.257 157.293 155.491C151.504 150.848 144.991 146.146 138.021 144.128C123.27 139.855 108.152 136.294 92.9406 134.291C66.4467 130.777 40.65 125.119 16.2346 114.177C10.8319 111.765 5.81513 108.507 0.558041 105.6Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default Logo;
