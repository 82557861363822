import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";
// import "swiper/components/scrollbar/scrollbar.scss";

import "swiper/css";

import "../../../css/swiper.scss";
import "../../../css/navigation.scss";
import "../../../css/pagination.scss";
import "../../../css/scrollbar.scss";

import SvCard from "../SvCard";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
const SimpleSlider = (props) => {
  return (
    <Swiper
      centeredSlides={true}
      spaceBetween={30}
      slidesPerView="auto"
      pagination={{ clickable: true }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <SvCard
          label="Импорт"
          info="Комплексные поставки любых товаров из любой точки мира, сырьё, запасные
        части, оборудования, товаров для строительства и промышленного
        использования, линий производства."
        ></SvCard>
      </SwiperSlide>
      <SwiperSlide>
        <SvCard
          label="Экспорт"
          info="Cвежей и переработанной плодоовощной продукции и осуществляет экспортные поставки на основе прямых экспортных контрактов с иностранными партнерами."
        ></SvCard>
      </SwiperSlide>
      <SwiperSlide>
        <SvCard
          label="Агрокультура"
          info="Группа компаний “PBS Group” занимается сельскохозяйственным производством в разных регионах Республики Узбекистан."
          check={true}
          button="•••"
          href="https://agrione.uz/"
        ></SvCard>
      </SwiperSlide>
      <SwiperSlide>
        <SvCard
          label="Грузоперевозки"
          info="Грузоперевозки являются одним из важнейших и приоритетных направлений, при помощи которой возможно обеспечение динамичного роста экономик государств."
        ></SvCard>
      </SwiperSlide>
      <SwiperSlide>
        <SvCard
          label="Декларантские услуги"
          info="Предлагаем услуги таможенных декларантов 24 часа в сутки 7 дней в неделю. Работаем быстро, качественно и предлагаем доступные цены на услуги."
        ></SvCard>
      </SwiperSlide>
      <SwiperSlide>
        <SvCard
          label="ИТ услуги"
          info="Компания ООО «PBS GROUP» является лидером в сфере IT-разработок, интеграции, 
                          автоматизации для государственных и негосударственных коммерческих предприятий 
                          не только на территории Республики Узбекистан, но и за рубежом."
        ></SvCard>
      </SwiperSlide>
    </Swiper>
  );
};

export default SimpleSlider;
