import React from "react";
import Star from "../../assets/svg/Star";

function index({ label, info, hasBorder, button, href, check }) {
  return (
    <div className={`sv-card ${hasBorder ? "borderNone" : ""}`}>
      <div className="sv-card__label">{label}</div>
      <div className="sv-card__icon">
        <Star />
      </div>
      <div className="sv-card__info">
        {info}
        <br />
        {check && (
          <a href={href} target="_blank">
            <button>{button}</button>
          </a>
        )}
      </div>
    </div>
  );
}

export default index;
